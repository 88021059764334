import { useState, useCallback } from "react";

export const useSort = (sortKeys, sortOrder = "ASC") => {
  const [sortData, setSortData] = useState({ key: sortKeys[0], order: sortOrder });

  const sortBy = useCallback((items, sortData) => {
    const handleSort = (left, right, key) => {
      const aValue = left[key];
      const bValue = right[key];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortData.order === 'ASC' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      
      return sortData.order === 'ASC' ? aValue - bValue : bValue - aValue;
    }

    setSortData(sortData);
    return [...items].sort((left, right) => handleSort(left, right, sortData.key));
  }, []);

  return {
    sortBy,
    sortData,
  };
}
