export const CREATE = "create";
export const EDIT = "edit";

export const PROJECT = "project";
export const SUBJECT = "subject";
export const TASK = "task";

export const FEMALE = "female";
export const MALE = "male";

export const TaskState = Object.freeze({
    CREATED: 'CREATED',
    RUNNING: 'RUNNING',
    PAUSED: 'PAUSED',
    COMPLETED: 'COMPLETED'
});

export const MeasurementUnit = Object.freeze({
    VOLT: 'V',
    MICROVOLT: 'mV',
});

export const TimeSeriesScaleByUnit = Object.freeze({
    [MeasurementUnit.MICROVOLT]: 1,
    [MeasurementUnit.VOLT]: 1_000_000,
});

export const CHANNELS_COUNT_CATEGORY = Object.freeze({
    FULL: 'ChannelsCount.Full',
    HALF: 'ChannelsCount.Half',
    QUARTER: 'ChannelsCount.Quarter',
    OCTILE: 'ChannelsCount.Octile',
});

export const TIME_SERIES_PRESETS_OFFLINE = [{
    id: "off-1",
    title: "10 s. • 60 Hz • Full",
    durationInSeconds: 10,
    samplesPerSecond: 60,
    channelsCount: CHANNELS_COUNT_CATEGORY.FULL,
    divider: 1,
}, {
    id: "off-2",
    title: "30 s. • 60 Hz • Half",
    durationInSeconds: 30,
    samplesPerSecond: 60,
    channelsCount: CHANNELS_COUNT_CATEGORY.HALF,
    divider: 2,
}, {
    id: "off-3",
    title: "60 s. • 30 Hz • Quarter",
    durationInSeconds: 60,
    samplesPerSecond: 30,
    channelsCount: CHANNELS_COUNT_CATEGORY.QUARTER,
    divider: 4
}, {
    id: "off-4",
    title: "120 s. • 15 Hz • Quarter",
    durationInSeconds: 120,
    samplesPerSecond: 15,
    channelsCount: CHANNELS_COUNT_CATEGORY.QUARTER,
    divider: 4
}, {
    id: "off-5",
    title: "180 s. • 500 Hz • Full",
    durationInSeconds: 300,
    samplesPerSecond: 500,
    channelsCount: CHANNELS_COUNT_CATEGORY.FULL,
    divider: 1,
}];

export const TIME_SERIES_PRESETS_ONLINE = [{
    id: "on-1",
    title: "5 s. • 60 Hz • Full",
    durationInSeconds: 5,
    samplesPerSecond: 60,
    channelsCount: CHANNELS_COUNT_CATEGORY.FULL,
    divider: 1
}, {
    id: "on-2",
    title: "10 s. • 60 Hz • Half",
    durationInSeconds: 10,
    samplesPerSecond: 60,
    channelsCount: CHANNELS_COUNT_CATEGORY.HALF,
    divider: 2
}, {
    id: "on-3",
    title: "20 s. • 30 Hz • Quarter",
    durationInSeconds: 20,
    samplesPerSecond: 30,
    channelsCount: CHANNELS_COUNT_CATEGORY.QUARTER,
    divider: 4
}];

export const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

export const IGNORED_CHANNELS = ['Packet Counter', 'TRIGGER', '_']

export const INSIGHTS = {
    ATTENTION: "Concentration",
    RELAXATION: "Calmness",
    COMFORT: "Comfort",
    MENTAL_WORKLOAD: "Mental workload",
    MOTION_SICKNESS: "Motion sickness",
    STRESS: "Stress",
    VIGILANCE: "Situational awareness"
}

export const ChartColors = [
    0x7f00ff, 0x7a08fe, 0x7510fe, 0x7018fe, 0x6a20fe, 0x6528fe, 0x6030fd,
    0x5b38fd, 0x5640fc, 0x5147fc, 0x4b4ffb, 0x4657fb, 0x415efa, 0x3c66f9,
    0x376df8, 0x3274f7, 0x2d7bf6, 0x2782f5, 0x2289f4, 0x1d90f3, 0x1897f2,
    0x139df0, 0x0ea3ef, 0x09aaee, 0x03afec, 0x01b5eb, 0x06bbe9, 0x0bc0e7,
    0x10c5e6, 0x15cae4, 0x1bcfe2, 0x20d4e0, 0x25d8de, 0x2adcdc, 0x2fe0da,
    0x34e4d8, 0x39e7d6, 0x3febd4, 0x44eed2, 0x49f0cf, 0x4ef3cd, 0x53f5ca,
    0x58f7c8, 0x5ef9c5, 0x63fbc3, 0x68fcc0, 0x6dfdbe, 0x72febb, 0x77feb8,
    0x7cfeb5, 0x82feb2, 0x87feaf, 0x8cfead, 0x91fdaa, 0x96fca6, 0x9bfba3,
    0xa0f9a0, 0xa6f79d, 0xabf59a, 0xb0f397, 0xb5f093, 0xbaee90, 0xbfeb8d,
    0xc5e789, 0xcae486, 0xcfe082, 0xd4dc7f, 0xd9d87b, 0xded478, 0xe3cf74,
    0xe9ca71, 0xeec56d, 0xf3c069, 0xf8bb66, 0xfdb562, 0xffaf5e, 0xffaa5b,
    0xffa357, 0xff9d53, 0xff974f, 0xff904b, 0xff8947, 0xff8243, 0xff7b40,
    0xff743c, 0xff6d38, 0xff6634, 0xff5e30, 0xff572c, 0xff4f28, 0xff4724,
    0xff4020, 0xff381c, 0xff3018, 0xff2814, 0xff2010, 0xff180c, 0xff1008,
    0xff0804,
];
