import { INSIGHTS } from "../../../utils/constants";
import DivergentChart from './divergentChart'

const DivergentCharts = (
  {
    className = "",
    printLoading = () => {},
    data = {},
  }) => {

  return <>
    {printLoading()}
    <DivergentChart
      className={className}
      title={`Tasks ${INSIGHTS.ATTENTION} Summary`}
      data={data.analytics.tasks}
      metric={"attention_grade_summary"}
    />
    <DivergentChart
      className={className}
      title={`Tasks ${INSIGHTS.RELAXATION} Summary`}
      data={data.analytics.tasks}
      metric={"relaxation_grade_summary"}
    />
    <DivergentChart
      className={className}
      title={`Tasks ${INSIGHTS.STRESS} Summary`}
      data={data.analytics.tasks}
      metric={"stress_grade_summary"}
    />
    <DivergentChart
      className={className}
      title={`Tasks ${INSIGHTS.VIGILANCE} Summary`}
      data={data.analytics.tasks}
      metric={"vigilance_grade_summary"}
    />
    <DivergentChart
      className={className}
      title={`Tasks ${INSIGHTS.MENTAL_WORKLOAD} Summary`}
      data={data.analytics.tasks}
      metric={"mental_workload_grade_summary"}
    />
    <DivergentChart
      className={className}
      title={`Tasks ${INSIGHTS.MOTION_SICKNESS} Summary`}
      data={data.analytics.tasks}
      metric={"motion_sickness_grade_summary"}
    />
  </>;
}

export default DivergentCharts;
