import { connect } from 'react-redux';
import TaskCharts from './taskCharts';
import { sendAlerts, sendTriggers } from "../../store/slices/alert";
import { downloadData, selectChart, getTaskDetails, selectChartPreset } from "../../store/slices/tasks";

function mapStateToProps(state) {
  const { currentTask: taskData } = state.entities.tasks;
  return { taskData };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadData: (taskId, type, reduce) => dispatch(downloadData(taskId, type, reduce)),
    sendAlert: (data) => dispatch(sendAlerts(data)),
    sendTrigger: (data) => dispatch(sendTriggers(data)),
    setActiveChart: (chartType) => dispatch(selectChart(chartType)),
    setActiveChartPreset: (chartPreset) => dispatch(selectChartPreset(chartPreset)),
    getTaskDetails: (taskId) => dispatch(getTaskDetails(taskId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskCharts);