import {Col, Row} from "react-bootstrap";
import GradeChart from './gradeChart'
import { INSIGHTS } from "../../../utils/constants";

const GradeCharts = (
  {
    className = "",
    printLoading = () => {
    },
    data = {},
  }) => {

  return <Row xs={1} xxl={2} className={className} style={{margin: "1rem 1rem 0rem", position: 'relative'}}>
    {printLoading()}
    <Col xs={2} className={"mb-1 redesign-card"}>
      <GradeChart
        title={`${INSIGHTS.ATTENTION} grades`}
        data={data.analytics.attention_grade_summary}
        score={data.analytics.attention_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 ml-5 redesign-card"}>
      <GradeChart
        title={`${INSIGHTS.VIGILANCE} grades`}
        data={data.analytics.vigilance_grade_summary}
        score={data.analytics.vigilance_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 redesign-card"}>
      <GradeChart
        title={`${INSIGHTS.STRESS} grades`}
        data={data.analytics.stress_grade_summary}
        score={data.analytics.stress_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 ml-5 redesign-card"}>
      <GradeChart
        title={`${INSIGHTS.RELAXATION} grades`}
        data={data.analytics.relaxation_grade_summary}
        score={data.analytics.relaxation_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 redesign-card"}>
      <GradeChart
        title={`${INSIGHTS.MENTAL_WORKLOAD} grades`}
        data={data.analytics.mental_workload_grade_summary}
        score={data.analytics.mental_workload_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 ml-5 redesign-card"}>
      <GradeChart
        title={`${INSIGHTS.MOTION_SICKNESS} grades`}
        data={data.analytics.motion_sickness_grade_summary}
        score={data.analytics.motion_sickness_grade_summary?.score}
      />
    </Col>
  </Row>
}

export default GradeCharts;
