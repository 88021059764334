import { INSIGHTS } from "../../../utils/constants";
import SpectrumChart from './spectrumChart'

const SpectrumCharts = (
  {
    className = "",
    printLoading = () => {},
    data = {},
  }) => {

  return <>
    {printLoading()}
    <SpectrumChart
      className={className}
      title={`${INSIGHTS.ATTENTION} Detail`}
      data={data.analytics.attention_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title={`${INSIGHTS.MENTAL_WORKLOAD} Detail`}
      data={data.analytics.mental_workload_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title={`${INSIGHTS.STRESS} Detail`}
      data={data.analytics.stress_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title={`${INSIGHTS.RELAXATION} Detail`}
      data={data.analytics.relaxation_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title={`${INSIGHTS.VIGILANCE} Detail`}
      data={data.analytics.vigilance_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title={`${INSIGHTS.MOTION_SICKNESS} Detail`}
      data={data.analytics.motion_sickness_grade_tuples}
    />
  </>;
}

export default SpectrumCharts;
