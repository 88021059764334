import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import * as am5 from "@amcharts/amcharts5";
import EventMenu from "../../eventMenu";
import AlertsTable from "../../alertsTable";
import { useChart } from "../../../hooks/useChart";
import { AxisBullet } from "@amcharts/amcharts5/xy";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { getRangeIconColor, getRangeIconName } from "../../../utils/taskUtils";
import { appendAlertResponse, appendFeedback, getAlertsAndFeedbacks } from "../../../store/slices/alert";
import { ALERTS, TRIGGERS } from "../../../store/constants";

const CHART_ELEMENT_ID = "insights-chart";
const CHART_LEGEND_ELEMENT_ID = "insights-chart-legend";

const sortAndMergeAlerts = (alerts, feedbacks, triggers) => {
  let allAlerts = [];

  for (let i = 0; i < alerts.length; i++) {
    const { title, time, responseTime, responseTitle } = alerts[i];

    allAlerts.push({ type: "alert", time, title });

    if (responseTime || responseTime === 0)
      allAlerts.push({
        type: "alert response",
        time: responseTime,
        title: responseTitle,
        subject: title
      });
  }

  for (let i = 0; i < feedbacks.length; i++) {
    const { title, time } = feedbacks[i];
    allAlerts.push({ type: "feedback", time, title });
  }

  for (let i = 0; i < triggers.length; i++) {
    const { title, time } = triggers[i];
    allAlerts.push({ type: "trigger", time, title });
  }

  allAlerts.sort((left, right) => left.time - right.time);
  return allAlerts;
}

const channels = [
  "attention",
  "relaxation",
  // "comfort",
  "mental_workload",
  "motion_sickness",
  "stress",
  "vigilance",
];

const channelReprs = new Map([
  ["attention", "Concentration"],
  ["relaxation", "Calmness"],
  ["comfort", "Comfort"],
  ["mental_workload", "Mental workload"],
  ["motion_sickness", "Motion sickness"],
  ["stress", "Stress"],
  ["vigilance", "Situational awareness"],
]);

const InsightsChart = ({
  task,
  socket,
  sendAlert,
  sendTrigger,
}) => {
  const [alertsFeedbacksList, _setAlertsFeedbacksList] = useState([]);

  const alertsFeedbacksRef = useRef(alertsFeedbacksList);
  const setAlertsFeedbacksRef = (data) => {
    alertsFeedbacksRef.current = data;
    _setAlertsFeedbacksList(data);
  };

  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isChartReady, setIsChartReady] = useState(false);
  const { addData, updateLegend, drawChart, getSeries, getAxisX, getRoot } = useChart({
    data: [],
    chartId: CHART_ELEMENT_ID,
    legendId: CHART_LEGEND_ELEMENT_ID,
    emptyText: "No data available for insights of this task, you may have to wait for real-time data!",
    config: {
      yAxis: {
        min: 0,
        max: 1,
        strictMinMax: false,
        visible: false,
      }
    }
  });

  const addChartMarker = useCallback((value, endValue, color, axis, bullet) => {
    const rangeDataItem = axis.makeDataItem({ value, endValue });
    const range = axis.createAxisRange(rangeDataItem);

    if (endValue) {
      range.get("axisFill").setAll({
        fill: color,
        fillOpacity: 0.2,
        visible: true
      });
    }

    if (bullet) {
      rangeDataItem.set(
        "bullet",
        AxisBullet.new(getRoot(), {
          sprite: am5.Picture.new(getRoot(), {
            width: 20,
            height: 20,
            centerX: am5.percent(0),
            centerY: am5.percent(100),
            src: bullet,
          })
        })
      );

      return;
    }

    range.get("grid").setAll({
      stroke: color,
      strokeOpacity: 1,
      location: 1
    });
  }, [getRoot]);

  const addAlertChartMarker = useCallback((alertTime, iconName, iconColor) => {
    addChartMarker(
      moment().startOf("day").add(Math.round(alertTime / 1000), "seconds").valueOf() + 500,
      undefined,
      am5.color(0xFF0000),
      getAxisX(),
      `/images/icons/chart_icons/ic_${iconName}_${iconColor}.svg`
    );
  }, [
    getAxisX,
    addChartMarker,
  ]);

  const drawAlertsFeedbacks = useCallback((alertsFeedbacks) => {
    for (const alertFeedbackItem of alertsFeedbacks) {
      switch (alertFeedbackItem.type) {
        case "alert":
          addAlertChartMarker(
            alertFeedbackItem.time,
            getRangeIconName(alertFeedbackItem.title),
            getRangeIconColor("alertSend")
          );
          break;

        case "alert response":
          addAlertChartMarker(
            alertFeedbackItem.time,
            getRangeIconName(alertFeedbackItem.subject),
            getRangeIconColor(alertFeedbackItem.title)
          );
          break;

        case "feedback":
          addAlertChartMarker(
            alertFeedbackItem.time,
            getRangeIconName(alertFeedbackItem.title),
            getRangeIconColor("feedback")
          );
          break;

        case "trigger":
          addAlertChartMarker(
            alertFeedbackItem.time, 
            getRangeIconName(alertFeedbackItem.title), 
            getRangeIconColor("trigger")
          );
          break;

        default:
          continue;
      }
    }
  }, [
    addAlertChartMarker
  ]);

  useEffect(() => {
    if (!isChartReady) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/insights`)
        .then(({ data: { result: { insightData } } }) => {
          setIsLoading(false);
          drawChart({ onlyKeys: channels, useUppercaseKeys: true });

          if (insightData.length > 0) {
            setIsEmpty(false);
          }

          for (let i = 0; i < insightData.length; i++) {
            Object.keys(insightData[i]).forEach((key) => {
              if (channels.includes(key)) {
                addData(channelReprs.get(key), insightData[i]["time"], insightData[i][key]);
              }
            });
          }
        })
        .then(() => {
          updateLegend();

          // Mark chart as ready.
          setIsChartReady(true);

          // Fetch and show alerts and feedbacks.
          axios
            .get(`${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/alerts-feedbacks`)
            .then(({ data: { result: { alerts, feedbacks, triggers } } }) => {
              const alertsFeedbacks = sortAndMergeAlerts(alerts, feedbacks, triggers);
              setAlertsFeedbacksRef(alertsFeedbacks);
              drawAlertsFeedbacks(alertsFeedbacks);
            })
            .catch((error) => console.log(error));
        });
    }
  }, [
    drawChart,
    task.objectId,
    updateLegend,
    isChartReady,
    addData,
    drawAlertsFeedbacks
  ]);

  useEffect(() => {
    if (!socket || !isChartReady) {
      return;
    }

    const handleMessage = async (packet) => {
      const { data, taskId: packetTaskId, type: packetType, alert } = packet;

      if (task.objectId !== packetTaskId) {
        console.warn(`Wrong task received data for ${packetType}. Expected '${task.objectId}', but received '${packetTaskId}'.`);
        return;
      }

      switch (packetType) {
        case "INSIGHT_ADD":
          if (isEmpty) {
            setIsEmpty(false);
          }

          Object.keys(data).forEach((key) => {
            if (channels.includes(key)) {
              addData(channelReprs.get(key), data["time"], data[key]);
            }
          });

          updateLegend();
          break;

        case "CREATE_ALERT":
          if (packetTaskId !== task.objectId)
            break;

          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, { type: "alert", title: alert.title, time: alert.time }]);
          addAlertChartMarker(alert.time, getRangeIconName(alert.title), getRangeIconColor("alertSend"));
          break;

        case "ALERT_RESPONSE":
          const alertResponse = { ...alert, time: alert.responseTime, type: "alert response", title: alert.responseTitle, subject: alert.title }

          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, alertResponse]);
          addAlertChartMarker(alertResponse.responseTime, getRangeIconName(alertResponse.subject), getRangeIconColor(alert.responseTitle));
          break;

        case "CREATE_FEEDBACK":
          const feedback = { ...packet, type: "feedback", title: packet.title, time: packet.time };

          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, feedback]);
          addAlertChartMarker(packet.time, getRangeIconName(packet.title), getRangeIconColor("feedback"));
          break;

        case "CREATE_TRIGGER":
          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, { type: "trigger", title: alert.title, time: alert.time }]);
          addAlertChartMarker(alert.time, getRangeIconName(alert.title), getRangeIconColor("trigger"));
          break;

        default:
          break;
      }
    }

    socket.on("message", handleMessage);
    return () => socket.off("message", handleMessage)
  }, [
    socket,
    isChartReady,
    addAlertChartMarker,
    addData,
    isEmpty,
    task.objectId,
    updateLegend
  ]);

  const handleSendAlert = (title, key = "concentration") => {
    const seriesByKey = (getSeries().find((item) => item.key.toLowerCase() === key.toLowerCase()) || {})?.series;

    if (!seriesByKey) {
      return;
    }

    const lastDataItem = seriesByKey.dataItems[seriesByKey.dataItems.length - 1];
    const lastInsightTime = lastDataItem.get("valueX") - moment().startOf("day").valueOf();
    const lastInsightValue = lastDataItem.get("valueY");

    const alertData = {
      title,
      taskId: task.objectId,
      time: lastInsightTime,
      value: lastInsightValue,
    };

    sendAlert(alertData);
  }

  const handleTrigger = (title, key = "concentration") => {
    const seriesByKey = (getSeries().find((item) => item.key.toLowerCase() === key.toLowerCase()) || {})?.series;

    if (!seriesByKey) {
      return;
    }

    const lastDataItem = seriesByKey.dataItems[seriesByKey.dataItems.length - 1];
    const lastInsightTime = lastDataItem.get("valueX") - moment().startOf("day").valueOf();

    const triggerData = {
      title,
      taskId: task.objectId,
      time: lastInsightTime,
    };

    sendTrigger(triggerData);
  }

  return (
    <>
      <div style={{ height: "100%", position: "relative" }}>
        <div id={CHART_ELEMENT_ID} style={{ height: "500px" }}></div>
        <div id={CHART_LEGEND_ELEMENT_ID} style={{ height: "60px" }}></div>

        {isLoading && (
          <div className={"chart-overlay w-100 h-100 d-flex align-items-center justify-content-center"}>
            <Spinner animation="border" />
          </div>
        )}

        {!(task.state === "COMPLETED") && !isEmpty && (
          <div className="d-flex flex-column justify-content-between">
            <EventMenu sendFunction={handleSendAlert} label="Alerts" options={ALERTS}/>
            <EventMenu sendFunction={handleTrigger} label="Triggers" options={TRIGGERS}/>
          </div>
      )}

        {isEmpty && !isLoading && (
          <div className={"chart-overlay w-100 h-100 d-flex align-items-center justify-content-center"}>
            <span className={"text-white"}>
              No insights data is available for this task, you may have to wait for real-time data!
            </span>
          </div>
        )}
      </div>

      <AlertsTable className="mt-3" alerts={alertsFeedbacksRef.current} />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    getAlertsAndFeedbacks: (taskId) => dispatch(getAlertsAndFeedbacks(taskId)),
    appendAlertResponse: (alertResponse) => dispatch(appendAlertResponse(alertResponse)),
    appendFeedback: (feedback) => dispatch(appendFeedback(feedback)),
  };
}

export default connect(null, mapDispatchToProps)(InsightsChart);
