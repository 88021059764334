import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const EventMenu = ({ sendFunction, label, options = [] }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [customTrigger, setCustomTrigger] = useState('');

  const toggleSwitch = () => {
    setIsExpanded(!isExpanded);
  };

  const handleEventClick = (event) => {
    sendFunction(event);
  };

  const handleCustomTriggerChange = (e) => {
    setCustomTrigger(e.target.value);
  };

  const handleSendCustomTrigger = () => {
    if (customTrigger.trim()) {
      sendFunction(customTrigger);
      setCustomTrigger('');
    }
  };

  return (
    <div className="event-switch-container mt-3">
      <div className="d-flex align-items-center">
        <Button
          onClick={toggleSwitch}
          className="button d-flex justify-content-between event-toggle-button"
        >
          <span className="me-2">{label || 'Switch'}</span>
          {isExpanded ? <FaArrowLeft /> : <FaArrowRight />}
        </Button>

        {isExpanded && (
          <div className="event-options d-flex align-items-center ms-3">
            {options.map((event) => (
              <Button
                key={event}
                variant="outline-primary"
                className="button me-2 text-center event-trigger-button"
                onClick={() => handleEventClick(event)}
              >
                {event}{label === 'Alerts' ? '?' : ''}
              </Button>
            ))}
          </div>
        )}
      </div>

      {isExpanded && label === 'Triggers' && (
        <div className="d-flex align-items-center mt-3 custom-trigger-container">
          <input
            type="text"
            value={customTrigger}
            onChange={handleCustomTriggerChange}
            placeholder="Custom Trigger"
            className="form-control me-2 custom-trigger-input"
          />
          <Button
            onClick={handleSendCustomTrigger}
            variant="primary"
            className="send-trigger-button"
          >
            Send
          </Button>
        </div>
      )}
    </div>
  );
};

export default EventMenu;
