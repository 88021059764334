import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import TasksList from "./../components/tasksList";
import { getProjectDetails } from "../store/slices/projects";
import { getSubjectDetails } from "../store/slices/subjects";
import ProjectDetailsHeader from "../components/projectsList/projectDetailsHeader";
import SubjectDetailsHeader from "../components/subjectsList/subjectDetailsHeader";

const sorterKeys = ["createdAt", "completedAt", "title", "duration"];

const TasksListPage = ({
  currentSubject,
  getProjectDetails,
  getSubjectDetails,
  currentProject,
  tasksList,
}) => {
  const { projectId, subjectId } = useParams();
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  useEffect(() => {
    getProjectDetails(projectId);
    getSubjectDetails(subjectId);
  }, [tasksList.length, projectId, subjectId, getProjectDetails, getSubjectDetails]);

  return (
    <>
      <ProjectDetailsHeader 
        project={currentProject}
        href="/dashboard/projects/"
      />
      <SubjectDetailsHeader
        subject={currentSubject}
        project={currentProject}
        handleOptionsToggle={() => setIsOptionsVisible(!isOptionsVisible)}
        href={`/dashboard/projects/project/${currentProject?.objectId}/`}
        className="mt-52"
      />
      <TasksList
        key={subjectId}
        projectId={projectId}
        subjectId={subjectId}
        isOptionsVisible={isOptionsVisible}
        indexedFields={sorterKeys}
      />
    </>
  );
};

function mapStateToProps(state) {
  const { currentProject } = state.entities.projects;
  const { currentSubject } = state.entities.subjects;
  const { list: tasksList } = state.entities.tasks;
  return {
    currentProject,
    currentSubject,
    tasksList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectDetails: (projectId) => dispatch(getProjectDetails(projectId)),
    getSubjectDetails: (subjectId) => dispatch(getSubjectDetails(subjectId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksListPage);
